import React from 'react'
import Navigation from '../components/Navigation'


function ParentBootcamps() {
  return (
    <>
    <Navigation />
    <div className='text-3xl font-bold text-black'>ParentBootcamps</div>
    </>

  )
}

export default ParentBootcamps